export const checkVersion = () => {
  if (typeof window !== `undefined`) {
    var agent = window.navigator.userAgent,
      start = agent.indexOf('OS ');
    if (
      (agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) &&
      start > -1
    ) {
      return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
    }
    return 0;
  }
};

export const isSafari =
  typeof window !== `undefined` &&
  /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
