export const isSSRPageLink = (urls, link) => {
  return urls.some((page) => {
    return (
      link?.linkToPage?.includes?.(page.url) ||
      link?.linkToDynamicPage === page.name
    );
  });
};

export const SSRPageLinks = [
  { url: '/winner-ranking/project/', name: 'Project Detail' },
  { url: '/brands-creatives/company/', name: 'Company Detail' },
  { url: '/if-design-award-and-jury/jury/profile/', name: 'Jury Detail' },
];
