import React from 'react';
import pt from 'prop-types';
import Text from '../../../../ui/text';
import * as styles from './styles.module.scss';

const JuryDetailsHeader = ({
  title,
  firstName,
  surname,
  company,
  city,
  country,
  id,
}) => (
  <div className={styles.header}>
    <div className={styles.textWrapper}>
      <Text as="h1" type="h1" className={styles.name}>
        {[title, firstName, surname].filter(Boolean).join(' ')}
      </Text>
      <Text as="span" type="h2" className={styles.company}>
        {company}
      </Text>
      <Text as="span" type="h3" variant="semibold" className={styles.country}>
        {city}
        {city && country ? ',' : ''} {country}
      </Text>
    </div>
  </div>
);

JuryDetailsHeader.propTypes = {
  id: pt.number,
  firstName: pt.string,
  surname: pt.string,
  company: pt.string,
  city: pt.string,
  country: pt.string,
};

JuryDetailsHeader.defaultProps = {
  id: 0,
  firstName: '',
  surname: '',
  company: '',
  city: '',
  country: '',
};

export default JuryDetailsHeader;
