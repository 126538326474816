import React from 'react';
import pt from 'prop-types';
import Text from '../../../../ui/text';
import * as styles from './styles.module.scss';

const JuryDetailsVita = ({ vita }) => {
  return (
    <div className={styles.vita}>
      <Text as="p" type="p1">
        <div dangerouslySetInnerHTML={{ __html: vita }} />
      </Text>
    </div>
  );
};

JuryDetailsVita.propTypes = {
  vita: pt.string,
};

JuryDetailsVita.defaultProps = {
  vita: '',
};

export default JuryDetailsVita;
