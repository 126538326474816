// extracted by mini-css-extract-plugin
export var additionalInfo = "styles-module--additionalInfo--7459d";
export var arrow = "styles-module--arrow--d9243";
export var badge = "styles-module--badge--82840";
export var date = "styles-module--date--5ea30";
export var empty = "styles-module--empty--10c92";
export var fallback = "styles-module--fallback--110fa";
export var fallbackSvg = "styles-module--fallbackSvg--30763";
export var imageBadge = "styles-module--imageBadge--9bbd9";
export var img = "styles-module--img--19dec";
export var imgIsLogo = "styles-module--imgIsLogo--37e37";
export var imgWrapper = "styles-module--imgWrapper--423b6";
export var inSlider = "styles-module--inSlider--3efaa";
export var isContainImage = "styles-module--isContainImage--e710a";
export var isSmall = "styles-module--isSmall--973db";
export var isSquare = "styles-module--isSquare--7997b";
export var itemWrapper = "styles-module--itemWrapper--425ca";
export var skeletonImg = "styles-module--skeletonImg--27e02";
export var skeletonText = "styles-module--skeletonText--d4653";
export var small = "styles-module--small--f864b";
export var textBadge = "styles-module--textBadge--cf3b4";
export var textWrapper = "styles-module--textWrapper--5f188";
export var title = "styles-module--title--4ca8a";