import React from 'react';
import { getAPIRoot } from '../../../../helpers/fetch';
import JuryDetailTemplate from '../../../../templates/juryDetail';
import NotFoundPage from '../../../404';
import { trackNotFoundEvent } from '../../../../utils/application-insights';
import { API_ROUTES } from '../../../../utils/constants';

const JuryDetail = ({ serverData: { juriData } }) => {
  return juriData ? (
    <JuryDetailTemplate juriData={juriData} />
  ) : (
    <NotFoundPage />
  );
};

export async function getServerData(ctx) {
  const id = ctx.params.id;
  const apiRoot = await getAPIRoot();
  const locale = ctx.pageContext.locale || 'en';
  const apiUrl = `${apiRoot}${API_ROUTES.JUROR_DETAIL}/${id}?language=${locale}`;
  let res = null;

  try {
    res = await fetch(apiUrl);

    if (!res.ok) {
      throw new Error('Data fetching failed!');
    }

    if (res.status === 204) {
      return {
        status: 301,
        headers: {
          Location: `/${locale}/if-design-award-and-jury/`,
        },
        props: {},
      };
    }

    const juriData = await res.json();
    const regexSlug = new RegExp(juriData.slug);
    if (ctx.url.match(regexSlug)) {
      return {
        status: 200,
        props: { juriData },
      };
    } else {
      return {
        status: 301,
        headers: {
          Location: `/${locale}${juriData.slug}`,
        },
        props: {},
      };
    }
  } catch (error) {
    trackNotFoundEvent({
      type: 'Juror Detail',
      id,
      locale,
      apiRoot,
      apiUrl,
      apiStatusCode: res?.status,
    });

    return {
      status: 404,
      headers: {},
      props: {},
    };
  }
}

export default JuryDetail;
