import React, { useContext, useEffect, useState } from 'react';
import RandomJurors from '../../components/cms-modules/dynamicTeaser/components/randomJurors';
import JuryDetails from '../../components/jury/details';
import { MicrocopyContext } from '../../context/microcopy.context';
import { getCopy, withMicrocopy } from '../../helpers/copy';
import Container from '../../modules/container';
import Layout from '../../modules/layout';
import Loading from '../../components/ui/loading';
import Headline from '../../components/ui/headline';
import * as styles from './styles.module.scss';
import { useLocale } from '../../context/locale.context';

const JuryDetailTemplate = ({ juriData }) => {
  const { locale } = useLocale();

  const TranslatedContent = withMicrocopy(() => {
    const microcopy = useContext(MicrocopyContext);
    const headline = getCopy('html.relatedjurors', microcopy);

    return (
      <Layout
        title={`${juriData.firstName} ${juriData.surname}`}
        description={juriData?.vitaEnglish}
        slug={juriData.slug}
      >
        <Container fullWidth className={styles.jurorWrapper}>
          <Container>
            <JuryDetails data={juriData} />
          </Container>
        </Container>
        <div className={styles.moreJurorsWrapper}>
          <Container fluid>
            <Headline
              level="h3"
              type="h1"
              text={headline}
              className={styles.headline}
            />
            <RandomJurors
              beigeBackground
              amountOfRandomTeaser={3}
              layout="grid"
            />
          </Container>
        </div>
      </Layout>
    );
  }, locale);

  return <TranslatedContent />;
};

export default JuryDetailTemplate;
