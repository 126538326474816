import React, { useContext } from 'react';
import pt from 'prop-types';
import { combine } from '/src/helpers/styles';
import Text from '../text';
import Logo from '../../../images/logo.svg';
import { getCopy } from '../../../helpers/copy';
import { MicrocopyContext } from '../../../context/microcopy.context';
import * as styles from './styles.module.scss';

const Loading = ({ className }) => {
  const microcopy = useContext(MicrocopyContext);
  const loadingText = getCopy('label.loading', microcopy);

  return (
    <div className={combine(styles.loading, className)}>
      <Logo />
      <Text>{loadingText}</Text>
    </div>
  );
};

Loading.propTypes = {
  className: pt.string,
};

Loading.defaultProps = {
  className: '',
};

export default Loading;
