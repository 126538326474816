import React, { useRef, useEffect, useState } from 'react';
import pt from 'prop-types';
import * as styles from './customScrollable.module.scss';
import { combine } from '/src/helpers/styles';
import { checkVersion, isSafari } from '../../../helpers/userAgent';

const CustomScrollable = ({
  children,
  vertical,
  onlySmall,
  className,
  ...props
}) => {
  const containerRef = useRef();
  const trackRef = useRef();
  const thumbRef = useRef();

  // Safari iOS14 logic for scrollbars
  const [needsScrollbarTrack, setNeedsScrollbarTrack] = useState(false);
  const version = checkVersion();
  const hackNeeded = version >= 14 && isSafari;
  const onScroll = () => {
    if (hackNeeded) {
      const percentageScrolled =
        containerRef.current.scrollLeft /
        (containerRef.current.scrollWidth - containerRef.current.clientWidth);
      const thumbPosition =
        trackRef.current.clientWidth * percentageScrolled -
        thumbRef.current.clientWidth * percentageScrolled;
      thumbRef.current.style.transform = `translateX(${thumbPosition}px)`;
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      if (
        containerRef.current.scrollWidth > containerRef.current.clientWidth &&
        hackNeeded
      ) {
        setNeedsScrollbarTrack(true);
      }
    }
  }, []);

  useEffect(() => {
    if (hackNeeded && thumbRef.current) {
      thumbRef.current.style.width =
        (containerRef.current.clientWidth / containerRef.current.scrollWidth) *
          100 +
        '%';
    }
  });

  return (
    <>
      <div
        className={combine(
          styles.container,
          vertical && styles.vertical,
          onlySmall && styles.onlyXS,
          className
        )}
        ref={containerRef}
        onScroll={onScroll}
        {...props}
      >
        {children}
      </div>
      {needsScrollbarTrack && (
        <div className={styles.scrollbarTrack} ref={trackRef}>
          <div className={combine(styles.scrollbarThumb)} ref={thumbRef} />
        </div>
      )}
    </>
  );
};

CustomScrollable.propTypes = {
  children: pt.node.isRequired,
  vertical: pt.bool,
  onlySmall: pt.bool,
  className: pt.string,
};

CustomScrollable.defaultProps = {
  onlySmall: false,
  vertical: false,
  className: '',
};

export default CustomScrollable;
