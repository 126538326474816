import React from 'react';
import pt from 'prop-types';
import Text from '/src/components/ui/text';
import Link, { linkProptypes } from '../link';
import Image, { imageProptypes } from '../image';
import AwardBadge from '../awardBadge';
import { combine } from '/src/helpers/styles';
import Icon from '../icon';
import Skeleton from '../skeleton';
import RichText, { richTextPropTypes } from '../richText';
import Headline from '../headline';
import FallbackIcon from './icon/if_fallback_profiles.svg';
import * as styles from './styles.module.scss';
import { isSSRPageLink, SSRPageLinks } from '../../../helpers/isSSRPageLink';

const TeaserItem = ({
  image,
  year,
  title,
  subline,
  text,
  info,
  link,
  isInSlider,
  imageBadge,
  awardBadge,
  className,
  imageClassName,
  isSkeleton,
  disableGatsbyImage,
  aspectRatio,
  isSmall,
  showArrow,
  arrowSize,
  textBadge,
  unstyledFallback,
  onLinkClick,
}) => {
  const renderTeaserItem = () => (
    <>
      {!image?.image && !image?.url && !imageBadge && (
        <div
          className={combine(
            styles.imgWrapper,
            unstyledFallback ? styles.empty : styles.fallback
          )}
        >
          {!unstyledFallback && <FallbackIcon className={styles.fallbackSvg} />}
        </div>
      )}
      {(image?.image || image?.url) && (
        <Image
          image={image?.image ? image?.image : image}
          className={styles.imgWrapper}
          imgClassName={combine(
            styles.img,
            image.isContainImage && styles.isContainImage,
            imageClassName
          )}
          disableImageCaption
          disableGatsbyImage={disableGatsbyImage}
        />
      )}
      {!image?.image && !image?.url && imageBadge && (
        <Image
          image={{ url: imageBadge }}
          className={styles.imgWrapper}
          disableImageCaption
          disableGatsbyImage={disableGatsbyImage}
          imgClassName={combine(
            styles.img,
            styles.imgIsLogo,
            image.isContainImage && styles.isContainImage,
            imageClassName
          )}
        />
      )}
      <div className={styles.textWrapper}>
        {year && (
          <Text as="span" type="infotext" className={styles.date}>
            {year}
          </Text>
        )}
        {title && (
          <Text as="h3" type="h3" className={styles.title}>
            {title}
          </Text>
        )}
        {subline && (
          <Headline text={subline} level="span" type="p1" variant="semibold" />
        )}
        {text && (
          <RichText
            content={text}
            paragraphAs="span"
            paragraphType="p1"
            paragraphVariant="light"
          />
        )}
        {info && (
          <Text as="span" type="metanavi" className={styles.additionalInfo}>
            {info}
          </Text>
        )}
      </div>
      {awardBadge && <AwardBadge name={awardBadge} className={styles.badge} />}
      {(image?.image || image?.url) && imageBadge && (
        <div
          className={combine(
            styles.imageBadge,
            (image.image || image.url) && styles.small
          )}
        >
          <Image image={{ url: imageBadge }} />
        </div>
      )}
      {textBadge && (
        <div className={styles.textBadge}>
          <Text as="span" type="infotext">
            {textBadge}
          </Text>
        </div>
      )}
      {showArrow && (
        <Icon name="arrow-right" size={arrowSize} className={styles.arrow} />
      )}
    </>
  );

  if (isSkeleton) {
    return (
      <div
        className={combine(
          styles.itemWrapper,
          isInSlider && styles.inSlider,
          aspectRatio === '1/1' && styles.isSquare,
          isSmall && styles.isSmall,
          className
        )}
      >
        <div className={styles.imgWrapper}>
          <Skeleton className={styles.skeletonImg} />
        </div>
        <div className={styles.textWrapper}>
          <Skeleton className={styles.skeletonText} />
        </div>
      </div>
    );
  }
  if (link) {
    return (
      <Link
        link={link}
        useGatsbyLink={!isSSRPageLink(SSRPageLinks, link)}
        className={combine(
          styles.itemWrapper,
          isInSlider && styles.inSlider,
          aspectRatio === '1/1' && styles.isSquare,
          isSmall && styles.isSmall,
          className
        )}
        onClick={onLinkClick}
      >
        {renderTeaserItem()}
      </Link>
    );
  }

  return (
    <a
      className={combine(
        styles.itemWrapper,
        isInSlider && styles.inSlider,
        aspectRatio === '1/1' && styles.isSquare,
        isSmall && styles.isSmall,
        className
      )}
    >
      {renderTeaserItem()}
    </a>
  );
};

TeaserItem.propTypes = {
  image: pt.shape(imageProptypes),
  title: pt.string,
  subline: pt.string,
  text: pt.shape(richTextPropTypes),
  info: pt.string,
  link: pt.shape(linkProptypes),
  awardBadge: pt.string,
  imageBadge: pt.string,
  isInSlider: pt.bool,
  className: pt.string,
  imageClassName: pt.string,
  showArrow: pt.bool,
  arrowSize: pt.string,
  textBadge: pt.string,
  isSkeleton: pt.bool,
  disableGatsbyImage: pt.bool,
  aspectRatio: pt.oneOf(['1/1', '16/12']),
  isSmall: pt.bool,
  unstyledFallback: pt.bool,
  onLinkClick: pt.func,
};

TeaserItem.defaultProps = {
  link: null,
  image: null,
  title: '',
  className: '',
  imageClassName: '',
  info: '',
  subline: '',
  text: null,
  awardBadge: '',
  imageBadge: '',
  isInSlider: false,
  showArrow: false,
  arrowSize: '2x',
  disableGatsbyImage: true,
  isSkeleton: false,
  aspectRatio: '16/12',
  isSmall: false,
  unstyledFallback: false,
};

export default TeaserItem;
