import pt from 'prop-types';
import React from 'react';
import { useLocale } from '../../../context/locale.context';
import { getCountryName } from '../../../utils/constants';
import MediaGallery from '../../contributions/detail/components/mediaGallery';
import JuryDetailsHeader from './components/header';
import JuryDetailsVita from './components/vita';
import * as styles from './styles.module.scss';

const JuryDetails = ({ data }) => {
  const { locale } = useLocale();
  const photoGallery = [data.pictureProfile].filter(Boolean).map((url) => ({
    url,
    type: 1,
  }));

  return (
    <div className={styles.profile}>
      {data.pictureProfile && (
        <MediaGallery
          media={photoGallery}
          className={styles.imageWrapper}
          isJuryPage
          isZoomable={false}
        />
      )}
      <div>
        <JuryDetailsHeader
          title={data.title}
          firstName={data.firstName}
          surname={data.surname}
          company={data.company}
          city={data.city}
          country={getCountryName(locale, data.country)}
          id={data.id}
        />
        {data.vitaEnglish && <JuryDetailsVita vita={data.vitaEnglish} />}
        {/* COMMENTED OUT TEMPORARILY */}
        {/*data.fieldsOfWork && data.fieldsOfWork.length > 0 && (
          <JuryDetailsWork fieldsOfWork={data.fieldsOfWork} />
        )*/}
      </div>
    </div>
  );
};

JuryDetails.propTypes = {
  data: pt.object.isRequired,
};

export default JuryDetails;
