import pt from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { combine } from '../../../../../helpers/styles';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { breakpoints } from '../../../../../utils/constants';
import CustomScrollable from '../../../../ui/customScrollable';
import Icon from '../../../../ui/icon';
import Image from '../../../../ui/image';
import Video from '../../../../ui/video';
import * as styles from './styles.module.scss';

const MediaGallery = ({ className, media, isZoomable, isJuryPage }) => {
  const mobileScrollContainerOutter = useRef();
  const [clicked, setClicked] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedImageOffsetLeft, setSelectedImageOfsetLeft] = useState(0);
  const { width } = useWindowSize();

  useLayoutEffect(() => {
    setSelectedImageOfsetLeft(
      document.querySelector(`#galleryImage${selectedImage}`).offsetLeft
    );
    mobileScrollContainerOutter.current.firstChild.scrollLeft =
      selectedImageOffsetLeft;
  }, [clicked, selectedImageOffsetLeft]);
  const handleClick = (e) => {
    if (!isZoomable) return false;
    if (!clicked && width < breakpoints.large) {
      setClicked(true);
      document.documentElement.style.overflow = 'hidden';
      getSelectedElementMobile(e);
    }
  };
  const closeFn = () => {
    if (clicked && width < breakpoints.large) {
      setClicked(false);
      document.documentElement.style.overflow = 'scroll';
    }
  };

  const getSelectedElementMobile = (e) => {
    setSelectedImage(e.currentTarget.getAttribute('data-select'));
  };

  const withScrollable = (className, children, id) => {
    if (media.length > 1) {
      return (
        <CustomScrollable className={className} id={id}>
          {children}
        </CustomScrollable>
      );
    }
    return children;
  };

  const videoPattern = /youtu.be|youtube|vimeo/;

  return (
    <>
      <div className={className}>
        {media &&
          withScrollable(
            combine(
              styles.scrollContainer,
              clicked ? styles.clicked : styles.notClicked
            ),
            media.map((item, index) => (
              <div key={index} data-select={index} onClick={handleClick}>
                {item.type === 1 ? (
                  <Image
                    disableGatsbyImage
                    image={item}
                    loading={item?.loading}
                    className={combine(
                      styles.imgWrapper,
                      isJuryPage && media.length < 2 && styles.jury
                    )}
                    imgClassName={styles.img}
                  />
                ) : (
                  videoPattern.test(item.url) && (
                    <Video
                      video={{
                        youtubeUrl:
                          (item.url.includes('youtube') ||
                            item.url.includes('youtu.be')) &&
                          item.url,
                        vimeoUrl: item.url.includes('vimeo') && item.url,
                      }}
                      className={styles.videoWrapper}
                      autoplay={false}
                    />
                  )
                )}
              </div>
            ))
          )}
      </div>
      <div className={combine(styles.mobileGallery, clicked && styles.showing)}>
        <Icon
          name="times"
          type="fal"
          className={combine(styles.closeBtn, clicked && styles.showing)}
          size="2x"
          color="white"
          onClick={closeFn}
        />
        <div
          className={styles.innerContainer}
          ref={mobileScrollContainerOutter}
        >
          {media &&
            withScrollable(
              combine(
                styles.scrollContainer,
                clicked ? styles.clicked : styles.notClicked
              ),
              media.map((item, index) => (
                <div key={index} id={`galleryImage${index}`}>
                  {item.type === 1 ? (
                    <Image
                      disableGatsbyImage
                      image={item}
                      loading={item?.loading}
                      className={combine(styles.imgWrapper, styles.mobile)}
                      imgClassName={styles.img}
                      isZoomable={isZoomable}
                      closeFn={closeFn}
                    />
                  ) : (
                    videoPattern.test(item.url) && (
                      <Video
                        video={{
                          youtubeUrl: item.url.includes('youtube') && item.url,
                          vimeoUrl: item.url.includes('vimeo') && item.url,
                        }}
                        className={styles.videoWrapper}
                        autoplay={false}
                      />
                    )
                  )}
                </div>
              )),
              'mobileGallery'
            )}
        </div>
      </div>
    </>
  );
};

MediaGallery.propTypes = {
  media: pt.array.isRequired,
  className: pt.string,
  isZoomable: pt.bool,
};

MediaGallery.defaultProps = {
  className: '',
  isZoomable: false,
};

export default MediaGallery;
