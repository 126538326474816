import React from 'react';
import pt from 'prop-types';
import RichText, { richTextPropTypes } from '../richText';
import Text from '../text';

const Headline = ({ text, level, type, variant, className, textClassName }) => {
  if (!text) return null;
  if (typeof text === 'string') {
    return (
      <Text
        as={level}
        type={type}
        variant={variant}
        className={className}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  }

  if (text.raw) {
    return (
      <RichText
        content={text}
        paragraphAs={level}
        paragraphType={type}
        paragraphVariant={variant}
        className={className}
        textClassName={textClassName}
      />
    );
  }

  return null;
};

export const headlinePropTypes = pt.oneOfType([
  pt.shape(richTextPropTypes),
  pt.string,
]).isRequired;

Headline.propTypes = {
  text: headlinePropTypes,
  level: pt.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']).isRequired,
  type: pt.string,
  variant: pt.string,
  className: pt.string,
  textClasName: pt.string,
};

Headline.defaultProps = {
  text: null,
  variant: 'light',
  className: '',
  textClassName: '',
  type: 'h1',
};

export default Headline;
