import React from 'react';
import pt from 'prop-types';
import Link, { linkProptypes } from '../../../../ui/link';
import Headline, { headlinePropTypes } from '../../../../ui/headline';
import Text from '../../../../ui/text';
import * as styles from './styles.module.scss';

const FirstItem = ({ headline, text, link }) => (
  <div className={styles.wrapperheader}>
    {headline && (
      <Headline
        text={headline}
        level="span"
        type="h1"
        className={styles.headline}
      />
    )}
    {text && (
      <Text as="span" type="p1" className={styles.text}>
        {text}
      </Text>
    )}
    {link && (
      <Link link={link} asButton className={styles.cta}>
        {link.text}
      </Link>
    )}
  </div>
);

FirstItem.propTypes = {
  headline: pt.shape(headlinePropTypes),
  text: pt.string,
  link: pt.shape(linkProptypes),
};

FirstItem.defaultProps = {
  headline: null,
  text: '',
  link: null,
};

export default FirstItem;
