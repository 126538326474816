// extracted by mini-css-extract-plugin
export var clicked = "styles-module--clicked--6255c";
export var closeBtn = "styles-module--closeBtn--3f610";
export var img = "styles-module--img--b0d4a";
export var imgWrapper = "styles-module--imgWrapper--ffc9e";
export var innerContainer = "styles-module--innerContainer--b5097";
export var jury = "styles-module--jury--e0fba";
export var mobile = "styles-module--mobile--e5133";
export var mobileGallery = "styles-module--mobileGallery--8d56a";
export var notClicked = "styles-module--notClicked--6b5d6";
export var scrollContainer = "styles-module--scrollContainer--b3e04";
export var showing = "styles-module--showing--bbd95";
export var videoWrapper = "styles-module--videoWrapper--cb956";