import React from 'react';
import pt from 'prop-types';
import { combine } from '../../../helpers/styles';
import * as styles from './styles.module.scss';

const Skeleton = ({ className }) => (
  <div className={combine(styles.parent, className)}>
    <div className={styles.pulse} />
  </div>
);

Skeleton.propTypes = {
  className: pt.string,
};

export default Skeleton;
